.comic {
  font-family: "Comic Sans MS", "Comic Sans", sans-serif;
  color: inherit;
}


.center {
  display: flex;
  justify-content: center;
}

/* background-color: #4158D0;
background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

background-color: #4158D0;
background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%); */
