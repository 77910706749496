body {
  margin: 0;
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* padding: 50px; */
}

code {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
}

